import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATHS } from 'utils/constants/index'
import { operatorLoginThunk } from 'redux-thunk/thunk/operators.thunk'
import { adminLogin } from 'redux-thunk/thunk/auth.thunk'

export const useLoginController = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [otp, setOtp] = useState('')
  const [otpRequired, setOtpRequired] = useState(false)
  const loginLoading = useSelector(state => state.loader.submitButtonLoader)

  const isOperator = window.location.pathname?.split('/')?.[1] === 'operator'

  useEffect(() => {
    document.title = isOperator ? "Operator Backoffice" : "Admin Backoffice"
  }, [])

  const loginSchema = yup.object({
    email: yup
      .string()
      .label(t('email'))
      .email(t('invalidEmailFormat'))
      .required((props) => t('isRequired', props)),
    password: yup
      .string()
      .label(t('password'))
      .min(4, (props) => t('numberMustbeAtleast', { ...props, minNumber: 4 }))
      .max(32, (props) => t('cannotExceedCharacters', { ...props, maxNumber: 32 }))
      .required((props) => t('isRequired', props)),
    ...(isOperator && ({
      operatorId: yup
        .string()
        .label(t('operatorId'))
        .required((props) => t('isRequired', props)),
    }))
  })

  const { handleSubmit, values, handleChange, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
      operatorId: ''
    },
    validationSchema: loginSchema,
    onSubmit: async ({ email, password, operatorId }) => {
      if (window.location.pathname === ROUTE_PATHS.login) {
        dispatch(adminLogin({
          token: Number(otp),
          email,
          password,
          setOtpRequired,
          navigate
        }))
      } else {
        dispatch(operatorLoginThunk({
          email,
          password,
          operatorId,
          navigate
        }))
      }
    }
  })

  return {
    t,
    otp,
    setOtp,
    otpRequired,
    handleSubmit,
    values,
    handleChange,
    errors,
    loginLoading
  }
}
