import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import utc from 'dayjs-plugin-utc'

import { ReactComponent as EnglishIcon } from 'assets/images/English.svg'

dayjs.extend(utc)

/* ==========================================================================
  LANGUAGES
  ========================================================================== */
const LANGUAGES = [
  { label: 'English', languageCode: 'en', flag: EnglishIcon }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
Operator Roles
========================================================================== */
const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Player Management Report Tabs
========================================================================== */

const PLAYER_REPORT_DATA_TAB = {
  ALL: 'all',
  CASINO: 'casino',
  CUSTOM: 'custom',
  SPORTSBOOK: 'sportsBook',
  CRYPTO: 'cryptoFutures',
  ROLLER_COASTER: 'rollerCoaster'
}

/* ==========================================================================
  Game Report Tabs
========================================================================== */

export const GAME_DATA_TAB = {
  CRASH: 'crash',
  DICE: 'dice',
  AZTECMAN: 'aztecman',
  SLOT: 'slot'
}

/* ==========================================================================
  Loader Types
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  Table Cell Type
========================================================================== */
const TABLE_CELL_TYPE = {
  status: 'status',
  description: 'description',
  switch: 'switch',
  switchStatus: 'switchStatus',
  button: 'button',
  date: 'date',
  dateOnly: 'dateOnly',
  text: 'text',
  customText: 'customText',
  link: 'link',
  navigateLink: 'navigateLink',
  image: 'image',
  number: 'number',
  integer: 'integer',
  amount: 'amount',
  customAmount: 'customAmount',
  default: 'default',
  color: 'color',
  crashMultiplier: 'crashMultiplier',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell',
  editAndDisableCell: 'editAndDisableCell',
  editDisableAndPinCell: 'editDisableAndPinCell',
  enableDisable: 'enableDisable',
  withdrawRequestAction: 'withdrawRequestAction',
  crmTemplateAction: 'crmTemplateAction',
  object: 'object',
  actionEnableDisableCell: 'actionEnableDisableCell',
  uploadIconButton: 'uploadIconButton',
  onlyEditAction: 'onlyEditAction',
  onlyViewAction: 'onlyViewAction',
  buttonWithCondition: 'buttonWithCondition',
  buttonForSettlement: 'buttonForSettlement',
  viewAndDisableEnableCell: 'viewAndDisableEnableCell',
  kycVerificationAction: 'kycVerificationAction',
  viewEditDelete: 'viewEditDelete',
  viewEditDeleteWhiteList: 'viewEditDeleteWhiteList',
  viewEditDeleteRestartable: 'viewEditDeleteRestartable',
  viewRefreshTransactionTable: 'viewRefreshTransactionTable',
  nestedValue: 'nestedValue',
  viewEdit: 'viewEdit'
}

export const DISABLE_ACTION_CELL = {
  check: 'check',
  eye: 'eye',
  edit: 'edit'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
const ROUTE_PATHS = {
  rootPath: '/',
  loginLog: '/login-logs',
  login: '/login',
  changePassword: '/change-password',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:adminId/:token',
  settings: '/settings',
  dashboard: '/dashboard',
  dashboard1: '/dashboard1',
  clientReports: '/reports/client',
  reportDetails: '/reports/client/details/:reportId',
  profile: '/profile',
  crashGameSettings: '/crash-game-settings',
  editCrashGameSettings: '/edit-crash-game-settings',
  gameSettings: '/game-settings',
  betResolver: '/bet-resolver',
  editGameSettings: '/game-settings/:id/edit',
  viewGameSettings: '/view-game-settings/:id/details',
  players: '/players',
  playersDetail: '/players/:playerId/details',
  createPlayer: '/players/create',
  editPlayer: '/players/:playerId/edit',
  operators: '/operators',
  operatorsDetail: '/operators/:operatorId/players',
  createOperator: '/operators/create',
  viewOperator: '/operators/details/:operatorId',
  whiteListOperator: '/operators/whitelist/:operatorId',
  editOperator: '/operators/:operatorId/edit',
  operatorLogin: '/operator/login',
  operatorLogout: '/operator/logout',
  operatorSettings: '/operator/settings',
  operatorGameSettings: '/operator/game-settings',
  operatorDashboard: '/operator/dashboard',
  operatorEditGameSettings: '/operator/:gameName/edit',
  operatorPlayers: '/operator/players',
  operatorOperators: '/operator/operators',
  operatorOperatorsDetail: '/operator/operators/:operatorId/players',
  operatorCreateOperator: '/operator/operators/create',
  operatorViewOperator: '/operator/operators/details',
  operatorEditOperator: '/operator/operators/:operatorId/edit',
  operatorSubAdmins: '/operator/operator-admins',
  operatorCreateOperatorAdmin: '/operator/operator-admin/create',
  operatorViewOperatorAdmin: '/operator/operator-admin/details',
  operatorEditOperatorAdmin: '/operator/operator-admin/edit',
  operatorAdmins: '/operator-admins',
  createOperatorAdmin: '/operator-admin/create',
  viewOperatorAdmin: '/operator-admin/details',
  editOperatorAdmin: '/operator-admin/edit',
  operatorTransactions: '/operator/transactions',
  gamesList: '/games-list',
  editGamesList: '/games-list/:gameId/edit',
  currencies: '/currencies',
  createCurrencies: '/create-currencies',
  influencers: '/influencers',
  influencerDetail: '/influencers/:userId/details',
  createInfluencer: '/influencers/create',
  editInfluencer: '/influencers/:userId/edit',
  clients: '/clients',
  createClient: '/clients/create',
  clientDetail: '/clients/:clientId/details',
  editClient: '/clients/:clientId/edit',
  databaseExplorer: '/database-explorer',
  admins: '/system-manage/admins',
  roleManagement: '/system-manage/role-manage',
  ipManagement: '/system-manage/ip-manage',
  operationLog: '/system-manage/operation-manage',
  systemManagementLoginLogs: '/system-manage/login-logs',
  siteConfiguration: '/system-manage/site-config',
  createRole: '/roles/create',
  editRole: '/roles/:adminId/edit',
  roles: '/system-manage/roles',
  rolesDetail: '/system-manage/roles/:roleId/details',
  games: '/games-manage/games-custom',
  gamesSportsbook: '/games-manage/games-sportsbook',
  gamesProviders: '/games-manage/games-provider',
  editProvider: '/providers/:providerId/edit',
  gamesAggregators: '/games-manage/games-aggregator',
  gamesCategories: '/games-manage/games-categories',
  categoryDetail: '/categories/:categoryId/details',
  createCategory: '/categories/create',
  editCategory: '/categories/:categoryId/edit',
  editSiteConfig: '/siteConfiguration/:siteId/edit',
  createAdmin: '/admins/create',
  adminsDetail: '/system-manage/admins/:adminId/details',
  editAdmin: '/admins/:adminId/edit',
  reportFlag: '/report-flag',
  reportedUserDetail: '/report-flag/:reportedUserId/details',
  banners: '/banners',
  createBanner: '/banners/create',
  editBanner: '/banners/:bannerId/edit',
  gameThemeSettings: '/game-theme-settings',
  gameHeroTheme: '/game-themes/hero',
  createGameHeroTheme: '/game-themes/hero/create',
  editGameHeroTheme: '/game-themes/hero/:heroThemeId/edit',
  gameBackgroundTheme: '/game-themes/background',
  createGameBackgroundTheme: '/game-themes/background/create',
  editGameBackgroundTheme: '/game-themes/background/:backgroundThemeId/edit',
  transactions: '/transactions',
  transactionDetails: '/transactions/details/:transactionId',
  pendingTransactionDetails: '/transactions/pending/details/:transactionId',
  affiliates: '/affiliates',
  settleAffiliateCommission: '/settle-affiliate-commission',
  createAffiliateCommission: '/affiliate-commission/create',
  editAffiliateSettings: '/affiliates/:affiliateId/edit',
  affiliatesPlayers: '/affiliates/:affiliateId/players',
  individualPlayersStats: '/individualPlayer/:id/details',
  affiliateTransactions: '/affiliate-transactions',
  assets: '/assets',
  cms: '/cms',
  createCms: '/cms/create',
  editCms: '/cms/:cmsId/edit',
  crmTemplate: '/crm-template',
  createCrmTemplate: '/crm-template/create',
  editCrmTemplate: '/crm-template/:crmTemplateId/edit',
  withdrawRequests: '/withdraw-requests',
  bonus: '/bonus',
  dailyBonus: '/bonus/daily',
  editDailyCashbackBonus: '/bonus/cashback/:bonusId/edit',
  editDailyRewardBonus: '/bonus/daily/:bonusId/edit',
  weeklyBonus: '/bonus/weekly',
  editWeeklyRewardBonus: '/bonus/weekly/:bonusId/edit',
  createDepositBonus: '/bonus/deposit/create',
  createOtherBonus: '/bonus/other/create',
  createFreeSpinBonus: '/bonus/free-spin/create',
  otherBonus: '/other-bonuses',
  depositBonus: '/bonus/deposit',
  freeSpinBonus: '/bonus/free-spin',
  depositBonusDetail: '/bonus/deposit/:bonusId/details',
  editDepositBonus: '/bonus/deposit/:bonusId/edit',
  editOtherBonus: '/bonus/other/:bonusId/edit',
  editFreeSpinBonus: '/bonus/free-spin/:bonusId/edit',
  otherBonusDetail: '/bonus/other/:bonusId/details',
  freeSpinBonusDetail: '/bonus/free-spin/:bonusId/details',
  editReferralBonus: '/bonus/:bonusId/edit-referral-bonus',
  editJoiningBonus: '/bonus/:bonusId/edit-joining-bonus',
  themeSettings: '/theme-settings',
  createThemeSetting: '/theme-setting/create',
  editThemeSetting: '/theme-settings/:themeSettingId/edit',
  upliftingWords: '/uplifting-words',
  editUpliftingWords: '/uplifting-words/edit',
  crashReports: '/reports/crash-reports',
  notFound: '/not-found',
  paymentTransactions: '/payment-transactions',
  chatRules: '/chat-rules',
  playerChats: '/player-chats',
  userChatDetails: '/user-chat/:id/details',
  words: '/offensive-words',
  createWord: '/offensive-word/create',
  chatLanguageRoom: '/chat-language-room',
  chatGroups: '/chat-groups',
  createChatGroups: '/chat-groups/create',
  editChatGroups: '/chat-groups/:id/edit',
  tipsTransaction: '/user-tips-transaction',
  createChatLanguageRoom: '/chat-language-room/create',
  referrals: '/referrals',
  referralBonus: '/referral-bonus-settings',
  editReferralBonusSettings: '/referralBonus/:bonusId/edit',
  referralPlayers: '/referrals/:referralId/players',
  individualReferralPlayers: '/individual-referrals/:referralId/details',
  rankingLevels: '/ranking-levels',
  createRankingLevel: '/ranking-levels/create',
  editRankingLevel: '/ranking-levels/:rankId/edit',
  cryptoInstruments: '/crypto-instruments',
  editCryptoInstruments: '/crypto-instruments/:cryptoInstrumentId/edit',
  kycVerification: '/kyc-verification',
  kycVerificationDetails: '/kyc-verification/:id/details',
  cashback: '/cashback',
  payTable: '/slot-pay-table',
  slot: '/slot',
  slotRoundHistory: '/slot-round-history',
  offensiveWords: '/offensive-words',
  gameReport: '/game-report',
  playerReport: '/player-report',
  // registrationReport: '/registration-report',
  individualGameReportDetails: '/game-report/:gameId/details',
  operatorReport: '/operator-report',
  individualOperatorReportDetails: '/operator-report/:operatorId/details',
  rounds: '/rounds'
}

/* ==========================================================================
  DIALOG Types
========================================================================== */
const ACTION_TYPE = {
  add: 'add',
  update: 'update',
  delete: 'delete',
  view: 'view',
  hide: '',
  pin: 'pin',
  reRequest: 're-requested',
  approve: 'approve',
  reject: 'reject',
  restart: 'restart',
  whiteList: 'whiteList'
}

const DEFAULT_GAME_ID = {
  CRASH: 1
}

const USER_TYPES = {
  BOT: 'BOT',
  USER: 'USER',
  ADMIN: 'ADMIN',
  INFLUENCER: 'INFLUENCER'
}

const USER_FILTER_FOR_MAIL = {
  ALL_USERS: 'ALL_USERS',
  ALL_ACTIVE_USERS: 'ALL_ACTIVE_USERS',
  LAST_ACTIVE_USERS_FROM_PAST_ONE_WEEK: 'LAST_ACTIVE_USERS_FROM_PAST_ONE_WEEK',
  LAST_ACTIVE_USERS_FROM_PAST_ONE_MONTH: 'LAST_ACTIVE_USERS_FROM_PAST_ONE_MONTH',
  LAST_ACTIVE_USERS_FROM_PAST_THREE_MONTHS: 'LAST_ACTIVE_USERS_FROM_PAST_THREE_MONTHS',
  LAST_ACTIVE_USERS_FROM_PAST_SIX_MONTHS: 'LAST_ACTIVE_USERS_FROM_PAST_SIX_MONTHS',
  LAST_ACTIVE_USERS_FROM_PAST_ONE_YEAR: 'LAST_ACTIVE_USERS_FROM_PAST_ONE_YEAR',
  ALL_INACTIVE_USERS: 'ALL_INACTIVE_USERS',
  LAST_INACTIVE_USERS_FROM_PAST_ONE_WEEK: 'LAST_INACTIVE_USERS_FROM_PAST_ONE_WEEK',
  LAST_INACTIVE_USERS_FROM_PAST_ONE_MONTH: 'LAST_INACTIVE_USERS_FROM_PAST_ONE_MONTH',
  LAST_INACTIVE_USERS_FROM_PAST_THREE_MONTHS: 'LAST_INACTIVE_USERS_FROM_PAST_THREE_MONTHS',
  LAST_INACTIVE_USERS_FROM_PAST_SIX_MONTHS: 'LAST_INACTIVE_USERS_FROM_PAST_SIX_MONTHS',
  LAST_INACTIVE_USERS_FROM_PAST_ONE_YEAR: 'LAST_INACTIVE_USERS_FROM_PAST_ONE_YEAR'
}

const AUTOMATIC_MAILS = [
  'WELCOME_ONBOARD',
  'WELCOME_AND_VERIFY_EMAIL',
  'RESET_PASSWORD'
]

const FONT_LIST = [
  'ui-monospace',
  'sans-serif',
  'system-ui',
  'serif',
  'monospace'
]

export const PERMISSION_LABEL = {
  R: 'Read',
  C: 'Create',
  T: 'Toggle',
  U: 'Update',
  D: 'Delete'
}

export const PERMISSIONS = {
  READ: 'R',
  CREATE: 'C',
  TOGGLE: 'T',
  UPDATE: 'U',
  DELETE: 'D'
}

export const ROLE_NAMES = {
  superAdministrator: 'Super Administrator',
  generalManager: 'General Manager',
  platformAdministrator: 'Platform Administrator'
}

export const THEME_MODE = {
  DARK: 'dark',
  LIGHT: 'light'
}

export const AFFILIATE_SETTLEMENT_STATUS = {
  COMPLETED: 'completed',
  PENDING: 'pending'
}

export const BONUS_STATUS = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  REFERRAL: 'referral',
  CASHBACk: 'cashback',
  OTHER_BONUS: 'other'
}

export const SYSTEM_KYC_STATUS = {
  PENDING: 'pending',
  ON_HOLD: 'onHold',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  RE_REQUESTED: 're-requested'
}

export const TRANSACTION_TYPE = [
  { id: 1, label: 'All', value: '' },
  { id: 2, label: 'Bet', value: 'bet' },
  { id: 3, label: 'Win', value: 'win' },
  { id: 4, label: 'Rollback', value: 'rollback' }
]

export const TRANSACTION_STATUS = [
  { id: 1, label: 'All', value: '' },
  { id: 2, label: 'Success', value: 'success' },
  { id: 3, label: 'Failed', value: 'failed' }
]

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
const TOKEN = 'admin_authtoken'
const LANGUAGE = 'admin_language'
const ADMIN_ID = 'admin_id'
const OPERATOR_ID = 'operator_id'
const ROLE = 'role'
const ROLES = {
  ADMIN: 'admin',
  OPERATOR: 'operator'
}

/* ==========================================================================
  Result types
========================================================================== */
export const RESULT_TYPES = {
  WON: 'won',
  LOST: 'lost'
}

// Export All constant variables
export {
  TOASTER_TYPE,
  LANGUAGES,
  TOKEN,
  LANGUAGE,
  ADMIN_ID,
  OPERATOR_ID,
  ROLE,
  ROLES,
  OPERATOR_ROLES,
  ROUTE_PATHS,
  LOADER_TYPE,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
  TABLE_CELL_TYPE,
  DEFAULT_GAME_ID,
  ACTION_TYPE,
  USER_TYPES,
  USER_FILTER_FOR_MAIL,
  FONT_LIST,
  AUTOMATIC_MAILS,
  PLAYER_REPORT_DATA_TAB
}

export const DEFAULT_ROW_PER_PAGE_NO = 10
export const DEFAULT_PAGE_NO = 1

export const DEFAULT_PRECISION = 2

export const ORDER_FILTER = [
  {
    id: '1',
    label: 'Ascending',
    value: '1',
    order: 'asc'
  }, {
    id: '2',
    label: 'Descending',
    value: '2',
    order: 'desc'
  }
]

export const ORDER_BY = [
  {
    id: '1',
    label: 'Id',
    value: '1',
    orderBy: 'id'
  },
  {
    id: '2',
    label: 'Name',
    value: '2',
    orderBy: 'name'
  }
]

export const ORDER_BY_FOR_OPERATOR_ADMIN = [
  {
    id: '1',
    label: 'Id',
    value: '1',
    orderBy: 'admin_id'
  },
  {
    id: '2',
    label: 'Name',
    value: '2',
    orderBy: 'admin_name'
  }
]

export const ORDER_BY_SORTING = [
  {
    id: '1',
    label: 'Id',
    value: '1',
    orderBy: 'id'
  },
  {
    id: '2',
    label: 'Amount',
    value: '2',
    orderBy: 'amount'
  },
  {
    label: 'CreatedAt',
    value: '3',
    orderBy: 'createdAt'
  }
]

export const ORDER_BY_FILTER = [
  {
    id: '1',
    label: 'Id',
    value: '1',
    orderBy: 'id'
  },
  {
    id: '2',
    label: 'User Code',
    value: '2',
    orderBy: 'user_code'
  },
  {
    id: '3',
    label: 'User Name',
    value: '3',
    orderBy: 'user_name'
  },
  {
    id: '4',
    label: 'First Name',
    value: '4',
    orderBy: 'first_name'
  },
  {
    id: '5',
    label: 'Last Name',
    value: '5',
    orderBy: 'last_name'
  }
]

export const SORTING_BY = [
  { id: uuidv4(), label: 'Id', value: '1', orderBy: 'id' },
  { id: uuidv4(), label: 'Amount', value: '2', orderBy: 'amount' },
  { id: uuidv4(), label: 'CreatedAt', value: '3', orderBy: 'createdAt' }
]

export const BOOLEAN_FILTERS = [
  {
    id: uuidv4(),
    label: 'True',
    value: 'true'
  },
  {
    id: uuidv4(),
    label: 'False',
    value: 'false'
  }
]

export const TIME_PERIOD = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
}

export const TIME_DURATION = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  THIS_YEAR: 'THIS_YEAR',
  ALL_TIME: 'ALL_TIME',
  CUSTOM: 'CUSTOM'
}

export const TIME_DESCRIPTION = {
  TODAY: {
    startDate: dayjs.utc().format('YYYY-MM-DD'),
    endDate: dayjs.utc().format('YYYY-MM-DD')
  },
  YESTERDAY: {
    startDate: dayjs.utc().subtract(1, 'day').format('YYYY-MM-DD'),
    endDate: dayjs.utc().subtract(1, 'day').format('YYYY-MM-DD')
  },
  THIS_WEEK: {
    startDate: dayjs.utc().startOf('week').format('YYYY-MM-DD'),
    endDate: dayjs.utc().format('YYYY-MM-DD')
  },
  THIS_MONTH: {
    startDate: dayjs.utc().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs.utc().format('YYYY-MM-DD')
  },
  THIS_YEAR: {
    startDate: dayjs.utc().startOf('year').format('YYYY-MM-DD'),
    endDate: dayjs.utc().format('YYYY-MM-DD')
  }
}

export const LABEL_COLORS = [
  '#1E75B81A',
  '#FF95001A',
  '#2AB52A1A',
  '#E647471A',
  '#A35EDA1A',
  '#8A6D631A',
  '#FF7FB81A',
  '#7F7F7F1A',
  '#C2D4261A',
  '#1FD7E31A',
  '#FFD7001A',
  '#00FF001A',
  '#7F007F1A',
  '#FF63471A',
  '#5A91B81A',
  '#00FFFF1A',
  '#B8860B1A',
  '#0000FF1A',
  '#00FF001A',
  '#B222221A'
]

export const LABEL_COLORS_BORDER = [
  '#1E75B8',
  '#FF9500',
  '#2AB52A',
  '#E64747',
  '#A35EDA',
  '#8A6D63',
  '#FF7FB8',
  '#7F7F7F',
  '#C2D426',
  '#1FD7E3',
  '#FFD700',
  '#00FF00',
  '#7F007F',
  '#FF6347',
  '#5A91B8',
  '#00FFFF',
  '#B8860B',
  '#0000FF',
  '#00FF00',
  '#B22222'
]
