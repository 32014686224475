import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCasinoTransactionsService, getCrashGamesSettingsService, getGamesListService, getGamesSettingsService, getSlotGameListElementsService, getSportsbettingTransactionsService, restartGameService, updateCrashGamesSettingsService, updateGameStatusService, updateGamesListService, updateGamesSettingsService } from 'network/services/gameSettings.service'
import { startLoader, stopLoader } from 'redux-thunk/redux/slices/loader.slice'

export const getGamesList = createAsyncThunk(
  'get/GamesList',
  async (data, thunkApi) => {
    try {
      const res = await getGamesListService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getGamesSettings = createAsyncThunk(
  'get/GamesSettings',
  async (data, thunkApi) => {
    try {
      const res = await getGamesSettingsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCrashGamesSettings = createAsyncThunk(
  'get/CrashGameSettings',
  async (data, thunkApi) => {
    try {
      const res = await getCrashGamesSettingsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateCrashGamesSettings = createAsyncThunk(
  'update/CrashGamesSettings',
  async ({ data, onSuccessRedirect }, thunkApi) => {
    try {
      thunkApi.dispatch(startLoader('submitButtonLoader'))
      const res = await updateCrashGamesSettingsService(data)
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      onSuccessRedirect()
      return res
    } catch (error) {
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const updateGamesSettings = createAsyncThunk(
  'update/GamesSettings',
  async ({ data, onSuccessRedirect }, thunkApi) => {
    try {
      thunkApi.dispatch(startLoader('submitButtonLoader'))
      const res = await updateGamesSettingsService(data)
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      onSuccessRedirect()
      return res
    } catch (error) {
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateGameStatus = createAsyncThunk(
  'update/GamesSettings',
  async ({ data, operatorId }, thunkApi) => {
    try {
      thunkApi.dispatch(startLoader('submitButtonLoader'))
      const res = await updateGameStatusService(data)
      thunkApi.dispatch(getGamesList())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const restartGame = createAsyncThunk(
  'restart/Game',
  async ({ data, handleDialogClose }, thunkApi) => {
    try {
      thunkApi.dispatch(startLoader('submitButtonLoader'))
      const res = await restartGameService(data)
      handleDialogClose()
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      return res
    } catch (error) {
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchSlotGameListElements = createAsyncThunk(
  'fetch/slot-game-pay-table',
  async (data, thunkApi) => {
    try {
      const res = await getSlotGameListElementsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch sportsbetting transactions
 */
export const fetchSportsbettingTransactions = createAsyncThunk(
  'fetch/sportsbetting-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getSportsbettingTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch casino transactions
 */
export const fetchCasinoTransactions = createAsyncThunk(
  'fetch/casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Games Image Thunk
 */
export const updateGamesList = createAsyncThunk(
  'update/rewardDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateGamesListService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
