import { Grid } from '@mui/material'
import { Login1Icon } from 'components/ui-kit/icons/iconComponents/Login1Icon'
import CustomButton from 'components/ui-kit/Button/index'
import CustomTextField from 'components/ui-kit/TextField'
import CustomTypography from 'components/ui-kit/Typography/index'
import { LOADER_TYPE } from 'utils/constants'
import { LoginContainer, LoginIconContainer, RootLoginContainer, WelcomeTextContainer } from './styles'
import { useLoginController } from './controller/useLoginController'
import AppLoader from 'components/ui-kit/AppLoader'

const Login = () => {
  const isOperator = window.location.pathname?.split('/')?.[1] === 'operator'

  const { t, otpRequired, otp, setOtp, handleSubmit, values, handleChange, errors, loginLoading } = useLoginController()
  return (
    <Grid container>
      <Grid item xs={12} lg={6} sx={{ position: 'relative' }}>
        <RootLoginContainer style={{ background: '#000000' }}>
          <LoginContainer sx={{ borderRadius: '25px' }}>
            <CustomTypography
              value={isOperator ? t('operatorLogin') : t('login')}
              variant='h5'
              sx={{
                textAlign: 'center', marginBottom: '10px', fontWeight: '600', color: (theme) => theme.colors.black
              }}
            />
            <br />
            <CustomTextField
              sx={{ marginBottom: '20px' }}
              label={t('email')}
              placeholder={t('email')}
              variant='outlined'
              fullWidth
              id='email'
              name='email'
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              helperText={errors.email}
              enablevalidation={Boolean(values.email || errors.email)}
              className='loginInput'
            />
            <CustomTextField
              sx={{ marginBottom: '20px' }}
              label={t('password')}
              type='password'
              placeholder={t('password')}
              variant='outlined'
              name='password'
              id='password'
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              helperText={errors.password}
              enablevalidation={Boolean(values.password || errors.password)}
              className='loginInput'
            />
            {isOperator &&
              <CustomTextField
                sx={{ marginBottom: '20px' }}
                label={t('operatorId')}
                placeholder={t('operatorId')}
                variant='outlined'
                fullWidth
                id='operatorId'
                name='operatorId'
                value={values.operatorId}
                onChange={handleChange}
                error={errors.operatorId}
                helperText={errors.operatorId}
                enablevalidation={Boolean(values.operatorId || errors.operatorId)}
              />}

            {otpRequired && (
              <CustomTextField
                label={t('Enter OTP')}
                variant='outlined'
                fullWidth
                id='otp'
                name='otp'
                type='number'
                value={otp}
                sx={{ letterSpacing: '10px' }}
                onChange={(e) => {
                  const value = e.target.value
                  String(value).length <= 6 && setOtp(value)
                }}
              />)}

            <CustomButton
              variant='contained'
              disabled={Boolean(errors.email) || Boolean(errors.password) || !values.email.length || loginLoading}
              onClick={handleSubmit}
              type='submit'
              sx={{ backgroundColor: (theme) => theme.colors.btnBg1, minWidth: '266px', width: '100%' }}
            >
              {
                loginLoading
                  ? <AppLoader variant={LOADER_TYPE.PULSE} size={5} />
                  : <CustomTypography sx={{ fontWeight: 'bold' }} value={t('login')} />
              }
            </CustomButton>
          </LoginContainer>
        </RootLoginContainer>

        <LoginIconContainer>
          <Login1Icon style={{ height: '100%', width: '100%' }} />
        </LoginIconContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: { xs: 'none', lg: 'block' },
          position: 'relative'
        }}
      >
        <WelcomeTextContainer className='loginBgWrap'>
          {/* <img src={logo} alt='logo' className='loginLogo' /> */}
        </WelcomeTextContainer>
      </Grid>
    </Grid>
  )
}

export default Login
