import { createAsyncThunk } from '@reduxjs/toolkit'
import { getDashboardCumulativeReportService, getDashboardData, getDashboardReportService, getPlayersReport } from 'network/services/dashboard.service'

export const fetchDashboardReport = createAsyncThunk(
  'fetch/DashboardReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getDashboardReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchDashboardCumulativeReport = createAsyncThunk(
  'fetch/DashboardCumulativeReport',
  async (data, thunkApi) => {
    try {
      const res = await getDashboardCumulativeReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchDashboardData = createAsyncThunk(
  'fetch/players-data',
  async (data, thunkApi) => {
    try {
      const res = await getPlayersReport(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchDashboardDataInfo = createAsyncThunk(
  'fetch/dashboard-data',
  async ({ params }, thunkApi) => {
    try {
      const res = await getDashboardData(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
