import { v4 as uuidv4 } from 'uuid'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import { ROUTE_PATHS } from 'utils/constants/index'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import EuroIcon from '@mui/icons-material/Euro'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
// import SportsIcon from '@mui/icons-material/Sports'
import ThreeSixtyOutlinedIcon from '@mui/icons-material/ThreeSixtyOutlined'
import FeedIcon from '@mui/icons-material/Feed'

export const adminSidebarOptions = [
  {
    key: uuidv4(),
    label: 'dashboard',
    path: ROUTE_PATHS.dashboard,
    icon: DashboardOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Reports',
    icon: FeedIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'Client Reports',
        path: ROUTE_PATHS.clientReports,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'Game Reports',
        path: ROUTE_PATHS.gameReport,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'Player Reports',
        path: ROUTE_PATHS.playerReport,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'Operator Reports',
        path: ROUTE_PATHS.operatorReport,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
    ]
  },
  {
    key: uuidv4(),
    label: 'Transactions',
    icon: PaidOutlinedIcon,
    path: ROUTE_PATHS.transactions,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Players',
    icon: GroupsOutlinedIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'playerList',
        path: ROUTE_PATHS.players,
        icon: CircleOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'Operators',
    path: ROUTE_PATHS.operators,
    icon: StarBorderOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Operator Admins',
    path: ROUTE_PATHS.operatorAdmins,
    icon: ManageAccountsIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Clients',
    path: ROUTE_PATHS.clients,
    icon: GroupsOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Game list',
    path: ROUTE_PATHS.gamesList,
    icon: SportsEsportsIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Game Settings',
    icon: SettingsSuggestIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'Crash Game',
        path: ROUTE_PATHS.crashGameSettings,
        icon: CircleOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'Fast Games',
        path: ROUTE_PATHS.gameSettings,
        icon: CircleOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'Currencies',
    path: ROUTE_PATHS.currencies,
    icon: EuroIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Rounds',
    icon: ThreeSixtyOutlinedIcon,
    path: ROUTE_PATHS.rounds,
    subMenus: []
  },
]

export const operatorSidebarOptions = [
  {
    key: uuidv4(),
    label: 'dashboard',
    path: ROUTE_PATHS.operatorDashboard,
    icon: DashboardOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Reports',
    icon: FeedIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'Game Reports',
        path: ROUTE_PATHS.gameReport,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'Player Reports',
        path: ROUTE_PATHS.playerReport,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
    ]
  },
  {
    key: uuidv4(),
    label: 'Players',
    icon: GroupsOutlinedIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'playerList',
        path: ROUTE_PATHS.players,
        icon: CircleOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'Transactions',
    path: ROUTE_PATHS.operatorTransactions,
    icon: PaidOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Game list',
    path: ROUTE_PATHS.gamesList,
    icon: SportsEsportsIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Rounds',
    icon: ThreeSixtyOutlinedIcon,
    path: ROUTE_PATHS.rounds,
    subMenus: []
  },
]
