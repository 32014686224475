import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getOperatorDetailService,
  createOperatorService,
  getAllOperatorsService,
  updateOperatorService,
  updateOperatorStatusService,
  deleteOperatorService,
  getAllOperatorAdminsService,
  createOperatorAdminService,
  updateOperatorAdminService,
  deleteOperatorAdminService,
  updateOperatorSecretKeyService,
  operatorLoginService,
  operatorLogoutService,
  getOperatorAdminDetailService,
  operatorWhitelistService,
  getOperatorWhitelistEndPointService,
  deleteOperatorWhitelistEndPointService
} from 'network/services/operators.service'
import { startLoader, stopLoader } from 'redux-thunk/redux/slices/loader.slice'
import { signIn, signOut } from 'utils/common-services/session.services'
import { ROLES, ROUTE_PATHS } from 'utils/constants/index'
// import { fetchAdminInformation } from './admin.thunk'

export const fetchAllOperators = createAsyncThunk(
  'fetch/allOperators',
  async (data, thunkApi) => {
    try {
      const res = await getAllOperatorsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Operator Login Thunk
 */
export const operatorLoginThunk = createAsyncThunk('Operator/login', async ({ email, password, operatorId, navigate }, thunkApi) => {
  try {
    const res = await operatorLoginService({ email, password, operatorId })
    signIn({
      token: res?.accessToken,
      adminId: res?.id,
      operatorId: res?.operatorId,
      role: ROLES.OPERATOR
    })
    navigate(ROUTE_PATHS.operatorDashboard, {
      replace: true
    })
    return res
  } catch (error) {
    console.log(error)
    return thunkApi.rejectWithValue(error[0].description)
  }
})

/**
 * Operator Logout Thunk
 */
export const operatorLogout = createAsyncThunk('operator/logout', async ({ navigate }, thunkApi) => {
  try {
    const res = await operatorLogoutService()
    signOut()
    navigate(ROUTE_PATHS.operatorLogin, {
      replace: true
    })
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const fetchOperatorAdminDetails = createAsyncThunk(
  'fetch/operator-admin-detail',
  async (data, thunkApi) => {
    try {
      const res = await getOperatorAdminDetailService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllOperatorAdmins = createAsyncThunk(
  'fetch/allOperatorAdmins',
  async (data, thunkApi) => {
    try {
      const res = await getAllOperatorAdminsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchOperatorDetails = createAsyncThunk(
  'fetch/operatorDetail',
  async ({ data }, thunkApi) => {
    try {
      thunkApi.dispatch(startLoader('submitButtonLoader'))
      const res = await getOperatorDetailService(data)
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createOperator = createAsyncThunk(
  'create/operator',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createOperatorService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const operatorWhitelisting = createAsyncThunk(
  'whitelist/operator',
  async ({ params, data, onSuccess }, thunkApi) => {
    try {
      const res = await operatorWhitelistService({ data, params })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperator = createAsyncThunk(
  'update/operator',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateOperatorService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperatorSecretKey = createAsyncThunk(
  'update/operatorSecretKey',
  async ({ data }, thunkApi) => {
    try {
      const res = await updateOperatorSecretKeyService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteOperator = createAsyncThunk(
  'delete/operator',
  async ({ operatorId, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await deleteOperatorService({ operatorId })
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperatorStatus = createAsyncThunk(
  'update/operatorStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateOperatorStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createOperatorAdmin = createAsyncThunk(
  'create/operatorAdmin',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createOperatorAdminService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperatorAdmin = createAsyncThunk(
  'update/operatorAdmin',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateOperatorAdminService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteOperatorAdmin = createAsyncThunk(
  'update/operatorAdmin',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await deleteOperatorAdminService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getOperatorWhitelistEndPoint = createAsyncThunk(
  'fetch/allOperatorWhitelistEndpoints',
  async (data, thunkApi) => {
    try {
      const res = await getOperatorWhitelistEndPointService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteOperatorWhitelistEndPoint = createAsyncThunk(
  'delete/operatorWhitelistEndPoint',
  async ({ data, onSuccessRedirect }, thunkApi) => {
    try {
      const res = await deleteOperatorWhitelistEndPointService(data)
      onSuccessRedirect()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
