import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllCurrenciesService,
  getAllTransactionsService,
  getTransactionDetailsService,
  resolveTransactionService
} from 'network/services/transaction.service'

export const fetchAllTransactions = createAsyncThunk(
  'fetch/allTransactions',
  async (data, thunkApi) => {
    try {
      const res = await getAllTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllCurrencies = createAsyncThunk(
  'fetch/allCurrencies',
  async (data, thunkApi) => {
    try {
      const res = await getAllCurrenciesService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchTransactionDetails = createAsyncThunk(
  'fetch/transaction-details',
  async (data, thunkApi) => {
    try {
      const res = await getTransactionDetailsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchResolvedTransactionDetails = createAsyncThunk(
  'fetch/resolve-transaction-details',
  async (data, thunkApi) => {
    try {
      const res = await resolveTransactionService(data)
      if (res && data.onSuccess) data.onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
