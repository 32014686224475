import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    serverName: 'admin-frontend',
    dsn: 'https://95b3c9bc87145234f3919a681d2a5ed3@o4508454139527168.ingest.us.sentry.io/4508529475846144',
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [window.location.origin]
  })
}
