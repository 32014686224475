import { useDispatch } from 'react-redux'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AppHeader from 'components/ui-kit/AppHeader/index'
import { RightSideOptionContainer, MaterialUISwitch } from './styles'
import { handleDrawerToggle } from 'redux-thunk/redux/slices/ham.slice'
import { handleLanguageChange } from 'redux-thunk/redux/slices/auth.slice'
import { useHeaderController } from './controller/useHeaderController'
import CustomButton from 'components/ui-kit/Button/index'
import CustomTypography from 'components/ui-kit/Typography/index'
import { ROUTE_PATHS } from 'utils/constants/index'
// import LanguageMenu from './components/LanguageMenu/index'
import { Box, FormGroup, FormControlLabel } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'


const Header = () => {
  const dispatch = useDispatch()
  const {
    t,
    navigate,
    // anchorElLanguage,
    // toggleLanguageMenu,
    // handleChangeLanguage,
    logoutHandler,
    toggleThemeHandler,
    // selectedLanguage,
    isSideNavOpen,
    currentTheme
  } = useHeaderController({
    handleLanguageChange
  })

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <AppHeader
      openDrawer={isSideNavOpen}
      handleDrawer={() => dispatch(handleDrawerToggle())}
      left={
        <Box sx={{ 
          textTransform: 'capitalize',
          fontWeight: '600',
          fontSize:'22px',
          display: isSmallScreen ? 'none' : 'block',
        }}>
          Bad Hombre
        </Box>
      }
      right={
        <RightSideOptionContainer>
          <Box
            sx={{ px: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <FormGroup>
              <FormControlLabel
                sx={{ mr: 0 }}
                control={<MaterialUISwitch />}
                label={<Box sx={{ mr: 0.8, fontSize: 14, textTransform: 'capitalize' }}>{t(currentTheme)}</Box>}
                onChange={toggleThemeHandler}
              />
            </FormGroup>
          </Box>

          {/* <LanguageMenu
            toggleLanguageMenu={toggleLanguageMenu}
            anchorElLanguage={anchorElLanguage}
            handleChangeLanguage={handleChangeLanguage}
            selectedLanguage={selectedLanguage}
          /> */}
          <CustomButton
            variant='contained'
            sx={{
              background: `${(theme) => theme.colors.tabBg}`,
              minWidth: '110px',
              borderRadius: '0.2rem',
              border: `2px solid ${(theme) => theme.colors.forestGreen}`,
              '&:hover': {
                background: 'transparent',
                border: `2px solid ${(theme) => theme.colors.forestGreen}`,
                '& svg': {
                  fill: `${(theme) => theme.colors.forestGreen}`
                },
                '& .MuiTypography-root': {
                  color: `${(theme) => theme.colors.forestGreen}`
                }
              }
            }}
            className='headerBtn'
            onClick={() => navigate(ROUTE_PATHS.settings)}
          >
            <ManageAccountsIcon sx={{ fill: (theme) => theme.colors.textWhite, marginRight: '10px' }} />
            <CustomTypography
              sx={{ fontWeight: '600' }}
              value={t('profile')}
            />
          </CustomButton>
          <CustomButton
            variant='outlined'
            id='logout-action'
            className='headerBtn'
            sx={{
              background: 'transparent',
              minWidth: '110px',
              border: '2px solid #389466',
              '& svg': {
                fill: '#389466'
              },
              '& .MuiTypography-root': {
                color: '#389466 !important'
              },
              '&:hover': {
                border: '2px solid #389466',
                background: 'linear-gradient(to bottom right, #389466, #42b883)',
                '& .MuiTypography-root': {
                  color: 'rgba(225, 225, 225, 1) !important'
                },
                '& svg': {
                  fill: 'rgba(225, 225, 225, 1)'
                }
              }
            }}
            onClick={logoutHandler}
          >
            <LogoutIcon sx={{ fill: (theme) => theme.colors.textWhite, marginRight: '10px' }} />
            <CustomTypography
              sx={{ fontWeight: '600' }}
              value={t('logout')}
            />
          </CustomButton>
        </RightSideOptionContainer>
      }
    />
  )
}

export default Header
