import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllCurrenciesService = () => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/currencies', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getAllGameTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/casino-transactions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getTransactionDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/get-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

// This will resolve the pending transaction
export const resolveTransactionService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/resolvers/resolve', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
  })
}
