import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Collapse, List, ListItem } from '@mui/material'
import CustomListItem from '../CustomListItem/index'
import CustomTypography from 'components/ui-kit/Typography/index'
import { useSelector } from 'react-redux'

const renderListItem = (item, { location, t, handleDrawerOptions, expandedMenuItem }) => {
  const NavIcon = item.icon
  return (
    <Fragment key={item.key}>
      <CustomListItem
        id={`sidebar_${item.label}`}
        {...(
          item.subMenus.length
            ? {}
            : { to: item.path, component: RouterLink }
        )}
        onClick={() => handleDrawerOptions(item.label, item.subMenus)}
        selected={item.path === location.pathname}
        sx={{
          color: (theme) => item.path === location.pathname ? theme.colors.white : theme.colors.grey500,
          fontWeight: item.path === location.pathname ? '800' : '400',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: '#d0cfcf'
          }
        }}
        icon={<NavIcon />}
        text={t(item.label)}
        expandless={
          item.subMenus.length > 0 &&
          expandedMenuItem.includes(item.label)
        }
        hasSubMenu={Boolean(item?.subMenus.length)}
      />
      {item.subMenus.length > 0 && (
        <Collapse
          in={expandedMenuItem.includes(item.label)}
          timeout='auto'
          unmountOnExit
        >
          <List component='div' disablePadding>
            {item.subMenus.map((subItem) => (
              <ListItem key={subItem.key} disablePadding>
                <CustomListItem
                  sx={{
                    color: (theme) => item.path === location.pathname ? theme.colors.white : theme.colors.grey500,
                    pl: 4,
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.2) !important',
                      color: '#d0cfcf'
                    }
                  }}
                  isbutton
                  id={`sidebar_${subItem.label}`}
                  to={subItem.path}
                  component={RouterLink}
                  text={t(subItem.label)}
                  icon={subItem.icon ? <subItem.icon /> : <></>}
                  hasSubMenu={false}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </Fragment>
  )
}

const CustomList = ({ t, location, list, handleDrawerOptions, expandedMenuItem }) => {
  const settingsItems = []
  const remainingItems = []

  const { openHam } = useSelector((state) => state?.ham)

  list.forEach(item => {
    if (item.label === 'bonusSetting' || item.label === 'chatSettings') {
      settingsItems.push(item)
    } else {
      remainingItems.push(item)
    }
  })

  return (
    <List>
      {remainingItems.map((item) => renderListItem(item, { location, t, handleDrawerOptions, expandedMenuItem }))}
      {openHam && settingsItems.length > 0 && (
        <ListItem>
          <CustomTypography value={t('settings')} variant='h5' sx={{ color: (theme) => theme.colors.grey500 }} />
        </ListItem>
      )}
      {settingsItems.map((item) => renderListItem(item, { location, t, handleDrawerOptions, expandedMenuItem }))}
    </List>
  )
}

CustomList.defaultProps = {
  list: [],
  handleDrawerOptions: () => { },
  expandedMenuItem: []
}

CustomList.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  handleDrawerOptions: PropTypes.func.isRequired,
  expandedMenuItem: PropTypes.array.isRequired
}

export default CustomList
