import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ROLES } from 'utils/constants/index'
import { getRole } from 'utils/common-services/session.services'
import { toggleTheme } from 'redux-thunk/redux/slices/theme.slice'
import { adminLogout } from 'redux-thunk/thunk/auth.thunk'
import { operatorLogout } from 'redux-thunk/thunk/operators.thunk'

const NAV_MENU_OPTIONS = {
  logout: 'logout',
  settings: 'settings',
  profile: 'profile'
}

export const useHeaderController = ({
  handleLanguageChange
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [anchorElLanguage, setAnchorElLanguage] = useState(null)
  const currentTheme = useSelector(state => state.theme.mode)
  const isSideNavOpen = useSelector(state => state.ham.openHam)
  const operatorId = useSelector(state => state.auth?.loginData?.operatorId)
  const selectedLanguage = useSelector(state => state.auth.language)
  const isOperator = getRole() === ROLES.OPERATOR

  const handleLogout = () => {
    if (isOperator || operatorId) {
      dispatch(operatorLogout({ navigate }))
    } else {
      dispatch(adminLogout({ navigate }))
    }
  }

  const toggleThemeHandler = () => {
    dispatch(toggleTheme())
  }

  const handleChangeLanguage = (languageCode) => {
    handleLanguageChange(languageCode)
    setAnchorElLanguage(null)
    i18n.changeLanguage(languageCode)
  }

  const toggleLanguageMenu = (event, closeMenu) => {
    if (closeMenu) {
      setAnchorElLanguage(null)
    } else {
      setAnchorElLanguage(event.currentTarget)
    }
  }

  const toggleUserMenu = (event, closeMenu) => {
    if (closeMenu) {
      setAnchorElUser(null)
    } else {
      setAnchorElUser(event.currentTarget)
    }
  }

  const logoutHandler = () => {
    localStorage.removeItem('recentTabs')
    handleLogout()
  }

  return {
    anchorElUser,
    selectedLanguage,
    isSideNavOpen,
    currentTheme,
    toggleUserMenu,
    t,
    navigate,
    NAV_MENU_OPTIONS,
    setAnchorElUser,
    anchorElLanguage,
    handleChangeLanguage,
    toggleLanguageMenu,
    logoutHandler,
    toggleThemeHandler,
    handleLogout
  }
}
