import AppRoutes from 'routes'
import { CssBaseline } from '@mui/material'
import { useSelector } from 'react-redux'
import AppLoader from 'components/ui-kit/AppLoader'
import AppToaster from 'components/ui-kit/AppToaster'
import { LOADER_TYPE } from 'utils/constants'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorHandler from 'components/layout/ErrorHandler/index'
import { StyledBodyContainer, StyledLoaderDiv } from 'App.styles'
import * as Sentry from '@sentry/react'

const App = () => {
  const { pageLoader } = useSelector(state => state.loader)
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Sentry.ErrorBoundary fallback={() => <></>}>
        <>
          <CssBaseline />
          {
            pageLoader &&
              <StyledLoaderDiv>
                <AppLoader variant={LOADER_TYPE.SCALE} />
              </StyledLoaderDiv>
          }
          <StyledBodyContainer pageloading={pageLoader}>
            <AppToaster />
            <AppRoutes pageLoader={pageLoader} />
          </StyledBodyContainer>
        </>
      </Sentry.ErrorBoundary>
    </ErrorBoundary>
  )
}

export default App
